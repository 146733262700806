<template>
	<div class="setup">
		<el-tabs v-model="activeName">
		    <el-tab-pane label="资料设置" name="material">
				<material></material>
			</el-tab-pane>
		    <el-tab-pane label="密码设置" name="cipher">
				<cipher></cipher>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import material from "/src/views/LearningCenter/material";//资料
	import cipher from "/src/views/LearningCenter/cipher";//密码
	export default {
		components: {
			material,cipher
		},
		data() {
			return {
				activeName:"material",//选项卡选中项
			}
		}
	}
</script>


<style scoped="scoped">
	.setup,.el-tabs,.el-tab-pane {
		width: 100%;
	}
</style>
<style>
	.setup .el-tabs__item{
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		color: #333;
		text-align: center;
		outline: none;
	}
	.setup .el-tabs__nav-wrap::after{
		height: 1px;
		background-color: #E6E7EB;
	}
	.setup .el-tabs__active-bar{
		background-color:#5E7BF2;
		height: 3px;
	}
	.setup .el-tabs__item.is-active{
		font-weight: bold;
	}
	.setup .el-tabs--top .el-tabs__item.is-top:nth-child(2){
		padding-left: 20px;
	}
</style>
