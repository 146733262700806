<template>
	<div class="material">
		<div class="avatar_box">
			<el-upload
			  class="avatar-uploader"
			  :action="PublicJs.publicPath + '/admin/ajax/upload.html'"
			  :show-file-list="false"
			  :on-success="portraitSuccess">
			  <img :src="imageUrl" class="avatar">
			  <div class="avatarTxet">
				  修改头像
			  </div>
			</el-upload>
		</div>
		<div class="avatarDes">支持200kb以下JPG、PNG格式的图片</div>
		<div class="U_form">
			<el-form :model="upForm" ref="upForm" label-width="170px" class="upForm">
				<el-form-item label="身份证号">
					<div>{{upForm.stu_idcard}}</div>
				</el-form-item>
				<el-form-item label="姓名">
					<div>{{upForm.stu_name}}</div>
				</el-form-item>
				<el-form-item label="性别" class="is-required">
					<el-radio v-model="upForm.stu_sex" label="男">男</el-radio>
					<el-radio v-model="upForm.stu_sex" label="女">女</el-radio>
				</el-form-item>
				<el-form-item label="手机号" class="is-required">
					<span>{{upForm.stu_phone}}</span>
					<span class="modifyBtn" @click="showChangeForm">修改</span>
				</el-form-item>
				<el-form-item label="邮箱" class="onlyInput" :class="{'is-error':upRules.stu_email}">
					<el-input v-model="upForm.stu_email" maxlength="25" @input="Email" @blur="blurEmail"></el-input>
					<div class="errorText" v-if="upRules.stu_email"><i class="el-icon-warning"></i>请输入正确的邮箱</div>
				</el-form-item>				
				<el-form-item label="单位地址" class="unitAddress is-required" :class="{'is-error':upRules.stu_areaid}">
					<el-select v-model="upForm.province" disabled>
						<el-option v-for="(item,index) in provinceList" :key="index" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="upForm.stu_cityid" placeholder="请选择市" disabled>
						<el-option v-for="(item,index) in cityList" :key="index" :label="item.are_name" :value="item.are_code">
						</el-option>
					</el-select>
					<el-select v-model="upForm.stu_areaid" placeholder="请选择区县" @change="changeArea">
						<el-option v-for="(item,index) in areaList" :key="index" :label="item.are_name" :value="item.are_id">
						</el-option>
					</el-select>
					<div class="errorText" v-if="upRules.stu_areaid"><i class="el-icon-warning"></i>请选择区县</div>
				</el-form-item>
				<el-form-item label="单位名称" class="is-required W100" :class="{'is-error':upRules.stu_emp_id}">
					<el-select
						v-model="upForm.stu_emp_id"
						filterable
						allow-create
						default-first-option
						placeholder="请选择或输入单位名称">
						<el-option
							v-for="(item,index) in CompanyList"
							:key="index"
							:label="item.emp_name"
							:value="item.emp_name">
						</el-option>
					</el-select>
					<div class="errorText" v-if="upRules.stu_emp_id"><i class="el-icon-warning"></i>请选择或输入单位</div>
				</el-form-item>
				<el-form-item label="专业技术职务系列" class="is-required onlyInput" :class="{'is-error':upRules.stu_dutid}">
					<el-select v-model="upForm.stu_dutid" placeholder="请选择专业技术职务系列">
					    <el-option
					      v-for="(item,index) in technicalList"
					      :key="index"
					      :label="item.dic_name"
					      :value="item.dic_id">
					    </el-option>
					</el-select>
					<div class="errorText" v-if="upRules.stu_dutid"><i class="el-icon-warning"></i>请选择专业技术职务系列</div>
				</el-form-item>
				<el-form-item label="职称" class="is-required onlyInput" :class="{'is-error':upRules.stu_pos_id}">
					<el-select v-model="upForm.stu_pos_id" placeholder="请选择职称">
					    <el-option
					      v-for="(item,index) in posList"
					      :key="index"
					      :label="item.dic_name"
					      :value="item.dic_id">
					    </el-option>
					</el-select>
					<div class="errorText" v-if="upRules.stu_pos_id"><i class="el-icon-warning"></i>请选择职称</div>
				</el-form-item>
				<el-form-item label="证书编号" class="onlyInput">
					<el-input v-model="upForm.stu_cert"></el-input>
				</el-form-item>
				<el-form-item class="upBtn_box">
					<el-button class="upBtn" type="primary" @click="submitForm()">提交</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 更改手机号码 -->
		<el-dialog title="更改手机号码" :visible.sync="changeVisible" width="400px" custom-class="changeDialog" :close-on-click-modal="false">
			<el-form :model="changeForm" label-width="70px">
				<el-form-item label="手机号码" :class="{'is-error':changeRules.stu_phone}">
					<el-input v-model="changeForm.stu_phone" maxlength="11" @input="Phone" @blur="blurPhone"></el-input>
					<div class="VerrorText" v-if="changeRules.stu_phone"><i class="el-icon-warning"></i>{{changeForm.phoneText}}</div>
				</el-form-item>
				<el-form-item label="验证码" :class="{'is-error':changeRules.VCode}">
					<el-input v-model="changeForm.VCode" class="VCode" @input="Phone" maxlength="6" @blur="blurVCode"></el-input>
					<el-button v-if="isSend">{{changeForm.times}}秒</el-button>
					<el-button v-else @click.prevent="getVerificationCode">{{changeForm.CodeText}}</el-button>
					<div class="VerrorText" v-if="changeRules.VCode"><i class="el-icon-warning"></i>{{changeForm.VCodeText}}</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="changeVisible = false">取 消</el-button>
				<el-button type="primary" @click="changeOk">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
	    data() {
			return {
				imageUrl: require("../images/nan.png"),
				upForm:{
					stu_idcard:"",//身份证
					stu_name:"",//姓名
					stu_sex:"男",//性别
					stu_phone:"",//电话号码
					stu_email:"",//邮箱
					province:0,//省份
					stu_cityid:"",//市
					stu_areaid:"",//区县
					stu_emp_id:"",//单位名称
					stu_dutid:"",//技术职务
					stu_pos_id:"",//职称
					stu_cert:"",//证书编号
					stu_headimg:"",//头像
					code:"",//验证码
				},
				upRules:{//验证
					stu_email:false,//邮箱
					stu_areaid:false,//区县
					stu_emp_id:false,//单位名称
					stu_pos_id:false,//职称
					stu_dutid:false,//技术职务
				},
				provinceList:[{
					label: "贵州省",
					value: 0
				}],//省份列表
				cityList:[],//城市列表
				areaList:[],//区县列表
				CompanyList:[],//单位列表
				technicalList:[],//技术职务
				posList:[],//职称列表
				changeVisible:false,//更改手机号码
				changeForm:{//更改手机弹窗
					stu_phone:"",
					VCode:"",
					times:60,//验证码倒计时
					CodeText:"获取验证码",//获取验证码
					phoneText:"",//手机验证文字
					VCodeText:"",//验证码验证文字
				},
				changeRules:{//更改手机弹窗验证
					stu_phone:false,
					VCode:false,
				},
				primaryPhone:"",//原手机号
				isSend:false,//是否发送验证码
				sendCode:"",//发送的验证码
				countdownFun:null,//倒计时
				primaryidcard:"",//原身份证号
			};
	    },
		methods:{
			//获取信息
			getUserinfo(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/getUserinfo.html", {
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.upForm.stu_idcard = this.PublicJs.Decrypt(response.data.data.stu_cardid).replace(/(.{3}).*(.{4})/, "$1******$2");//身份证号
						this.upForm.stu_name = response.data.data.stu_name;//姓名
						this.upForm.stu_sex = response.data.data.stu_sex;//性别
						this.upForm.stu_phone = this.PublicJs.Decrypt(response.data.data.stu_mobile).replace(/(.{3}).*(.{4})/, "$1******$2");//手机号
						this.upForm.stu_email = response.data.data.stu_email;//邮箱
						this.upForm.stu_cityid = response.data.data.get_code.are_code;//地址
						this.getAreaEmployer(this.upForm.stu_cityid,"area","",response.data.data.stu_areaid,response.data.data.get_employer.emp_name);//单位
						//技术职务
						this.getDropdownbox("duties",response.data.data.stu_dutid);
						//职称列表
						this.getDropdownbox("positional",response.data.data.stu_pos_id);
						this.primaryPhone = this.PublicJs.Decrypt(response.data.data.stu_mobile);//原手机
						this.primaryidcard = this.PublicJs.Decrypt(response.data.data.stu_cardid);//原身份证
						this.upForm.stu_cert = response.data.data.stu_cert;//证书编号
						if(response.data.data.stu_headimg){
							this.imageUrl = response.data.data.stu_headimg
						}else{
							if(response.data.data.stu_sex == "女"){
								this.imageUrl = require("../images/nv.png")
							}
						}
						var userinfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo')));
						userinfo.stu_sex = response.data.data.stu_sex;
						userinfo.stu_headimg = response.data.data.stu_headimg;
						userinfo.stu_cityid = response.data.data.stu_cityid;
						userinfo.city = response.data.data.get_code;
						window.sessionStorage.setItem('userinfo',this.PublicJs.Encrypt(JSON.stringify(userinfo)))
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//区县、单位
			getAreaEmployer(are_code,i_type,emp_areaid,stu_areaid,stu_emp_id){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/getAreaEmployer.html", {
					are_code:are_code,
					i_type:i_type,
					emp_areaid:emp_areaid,
					page:1,
					limit:999
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(i_type == "area"){
							this.areaList = response.data.data.data;
							if(stu_areaid){
								this.upForm.stu_areaid = stu_areaid;
								//获取单位
								this.getAreaEmployer("","employer",this.upForm.stu_areaid,"",stu_emp_id)
							}
						}else{
							this.CompanyList = response.data.data.data;
							if(stu_emp_id){
								this.upForm.stu_emp_id = stu_emp_id
							}else{
								this.upForm.stu_emp_id = ""
							}
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//选择区县后
			changeArea(val){
				//获取单位
				this.getAreaEmployer("","employer",val)
				if(val){
					this.upRules.stu_areaid = false
				}
			},
			//技术职务、职称列表
			getDropdownbox(dic_type,id){
				this.$http.post(this.PublicJs.publicPath + "/api/student.index/dictionary.html", {
					dic_type:dic_type,
					page:1,
					limit:999
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(dic_type == "duties"){
							this.technicalList = response.data.data.data;
							if(id){
								this.upForm.stu_dutid = id;
							}
						}else{
							this.posList = response.data.data.data;
							if(id){
								this.upForm.stu_pos_id = id;
							}
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//提交
			submitForm(){
				//区县验证
				if(!this.upForm.stu_areaid){
					this.upRules.stu_areaid = true
				}else{
					this.upRules.stu_areaid = false
				}
				//单位验证
				if(!this.upForm.stu_emp_id){
					this.upRules.stu_emp_id = true
				}else{
					this.upRules.stu_emp_id = false
				}
				//技术职务验证
				if(!this.upForm.stu_dutid){
					this.upRules.stu_dutid = true
				}else{
					this.upRules.stu_dutid = false
				}
				//职称验证
				if(!this.upForm.stu_pos_id){
					this.upRules.stu_pos_id = true
				}else{
					this.upRules.stu_pos_id = false
				}
				//邮箱验证
				this.blurEmail();
				if(!this.upRules.stu_email && !this.upRules.stu_areaid && !this.upRules.stu_emp_id && !this.upRules.stu_pos_id && !this.upRules.stu_dutid){
					var code = "";
					var stu_mobile = this.primaryPhone
					if(this.upForm.stu_phone.indexOf("*") == -1){
						if(this.primaryPhone != this.upForm.stu_phone){
							code = this.upForm.code;
							stu_mobile = this.upForm.stu_phone
						}
					}
					this.$http.post(this.PublicJs.publicPath + "/api/student.Info/setting.html", {
						token:window.sessionStorage.getItem('token'),
						stu_mobile:stu_mobile,
						stu_are_id:this.upForm.stu_areaid,
						stu_headimg:this.upForm.stu_headimg,
						stu_name:this.upForm.stu_name,
						stu_sex:this.upForm.stu_sex,
						stu_cardid:this.primaryidcard,
						stu_dutid:this.upForm.stu_dutid,
						stu_email:this.upForm.stu_email,
						code:code,
						stu_emp_name:this.upForm.stu_emp_id,
						stu_cert:this.upForm.stu_cert,
						stu_pos_id:this.upForm.stu_pos_id,
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.$message({
								message: response.data.msg,
								type: 'success',
								showClose: true
							});
							//更新信息
							var userinfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo')));
							userinfo.stu_sex = this.upForm.stu_sex;
							userinfo.stu_headimg = this.upForm.stu_headimg;
							window.sessionStorage.setItem('userinfo',this.PublicJs.Encrypt(JSON.stringify(userinfo)))
							this.$router.go(0)
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}
			},
			//邮箱只能输入数字字母和-_@.
			Email(){
				this.upForm.stu_email = this.upForm.stu_email.replace(/[^\da-zA-Z-_@.]/g,"");
			},
			//邮箱验证
			blurEmail(){
				if(this.upForm.stu_email){
					if(this.PublicJs.isEmailAvailable(this.upForm.stu_email)){
						this.upRules.stu_email = false;
					}else{
						this.upRules.stu_email = true;
					}
				}
			},
			//点击展示修改手机号码
			showChangeForm(){
				this.changeVisible = true;
				this.changeForm = {//更改手机弹窗
					stu_phone:"",
					VCode:"",
					times:60,//验证码倒计时
					CodeText:"获取验证码",//获取验证码
					phoneText:"",//手机验证文字
					VCodeText:"",//验证码验证文字
				};
				this.changeRules = {//更改手机弹窗验证
					stu_phone:false,
					VCode:false,
				};
				this.isSend = false;//是否发送验证码
				this.sendCode = "";//发送的验证码
				clearTimeout(this.countdownFun);//清除倒计时
			},
			//手机号码只能输入数字
			Phone(){
				this.changeForm.stu_phone = this.changeForm.stu_phone.replace(/[^\d]/g,"");
			},
			//手机号码验证
			blurPhone(){
				if(this.changeForm.stu_phone){
					if(this.PublicJs.phoneCheck(this.changeForm.stu_phone)){
						this.changeRules.stu_phone = false;
					}else{
						this.changeForm.phoneText = "请输入正确的手机号"
						this.changeRules.stu_phone = true;
					}
				}else{
					this.changeForm.phoneText = "请输入手机号"
					this.changeRules.stu_phone = true;
				}
			},
			//验证码
			blurVCode(){
				if(this.changeForm.VCode){
					if(this.isSend){
						this.changeRules.VCode = false;
					}else{
						this.changeForm.VCodeText = "请先获取验证码"
						this.changeRules.VCode = true;
					}
				}else{
					if(this.changeForm.stu_phone){
						this.changeForm.VCodeText = "请输入验证码"
						this.changeRules.VCode = true;
					}else{
						//手机号码验证
						this.blurPhone()
					}
				}
			},
			//点击获取验证码
			getVerificationCode(){
				if(this.changeForm.stu_phone){
					if(this.changeForm.stu_phone == this.primaryPhone){
						this.$message({
							message: "与原手机号码一致",
							type: 'warning',
							showClose: true
						});
					}else{
						this.$http.post(this.PublicJs.publicPath + "/api/sms/send.html", {
							mobile:this.PublicJs.Encrypt(this.changeForm.stu_phone),
							event:"changemobile",
							token:window.sessionStorage.getItem('token')
						}, {
							emulateJSON: true
						}).then(response => {
							if(response.data.code == 1){
								this.isSend = true;
								this.countdown(this);
								this.sendCode = response.data.data.code
							}else{
								this.$message({
									message: response.data.msg,
									type: 'warning',
									showClose: true
								});
							}
						}, response => {
							if(response.status == 401){
								var that = this;
								this.$message({
									message: "您的账号在别处登录，请重新登录",
									type: 'warning',
									showClose: true
								});
								window.sessionStorage.removeItem('user_id')
								window.sessionStorage.removeItem('token')
								window.sessionStorage.removeItem('userinfo')
								setTimeout(function(){
									that.$router.go(0)
								},2000); 
								
							}
						});
					}
				}else{
					this.changeForm.phoneText = "请输入手机号"
					this.changeRules.stu_phone = true;
				}
			},
			//60秒倒计时
			countdown(vm) {
				if (vm.changeForm.times == 0) {
					vm.isSend = false;
					vm.changeForm.CodeText = "重新获取";
					vm.changeForm.times = 60;
					return false;
				} else {
					vm.changeForm.times--;
				}
				vm.countdownFun = setTimeout(function() {
					vm.countdown(vm);
				}, 1000);
			},
			//更换手机确定
			changeOk(){
				//手机号码验证
				this.blurPhone();
				//验证码				this.blurVCode();
				if(!this.changeRules.stu_phone && !this.changeRules.VCode){
					if(this.PublicJs.Decrypt(this.sendCode) == this.changeForm.VCode){
						this.upForm.stu_phone = this.changeForm.stu_phone;
						this.upForm.code = this.changeForm.VCode;
					}else{
						this.$message({
							message: "验证码不正确，请重新输入",
							type: 'warning',
							showClose: true
						});
					}
					this.changeVisible = false;
				}
				
			},
			//头像上传成功
			portraitSuccess(response, file, fileList){
				if(response.code == 0){
					this.upForm.stu_headimg = response.data.url
					this.imageUrl = response.data.url
				}
			},
		},
		created() {
			//获取信息
			this.getUserinfo();
			//城市列表
			if(window.sessionStorage.getItem('cityList')){
				this.cityList = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('cityList')))
			}
			console.log(JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('userinfo'))))
		}
	}
		
</script>

<style scoped="scoped">
	.material{
		padding-top: 28px;
	}
	.avatar_box{
		width: 118px;
		height: 118px;
		margin: 0 auto;
		border-radius: 59px;
	}
	.avatar-uploader{
		width: 100%;
		height: 100%;
	}
	.avatar {
	    width: 100%;
	    height: 100%;
	    display: block;
	}
	.avatarTxet{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 118px;
		font-size: 14px;
		color: #FFF;
		background-color: rgba(51,51,51,0.5);
	}
	.avatarDes{
		font-size: 14px;
		color: #999;
		text-align: center;
		line-height: 50px;
	}
	.modifyBtn{
		color: #ffac3d;
		margin-left: 15px;
		cursor: pointer;
	}
	.modifyBtn:hover{
		color: #e88e16;
	}
	.onlyInput .el-input,
	.W100 .el-select{
		width: 588px;
		margin-right: 15px;
	}
	.unitAddress .el-select{
	    margin-right: 15px;
	    width: 186px;
	}
	.upBtn{
		width: 90px;
		height: 36px;
		background: #4d6ef2;
		border-radius: 4px;
		text-align: center;
		line-height: 36px;
		padding: 0;
		border: 1px solid #4d6ef2;
	}
	.VCode{
		width: 163px;
		margin-right: 15px;
	}
	.errorText{
		display: inline-block;
		color: #f56c6c;
		font-size: 14px;
		line-height: 40px;
	}
	.errorText i{
		margin-right: 5px;
	}
	.VerrorText{
		position: absolute;
		top: 44px;
		left: 0;
		line-height: 12px;
		font-size: 12px;
		color: #f56c6c;
	}
	.VerrorText i{
		margin-right: 5px;
	}
</style>

<style>
	.avatar_box .avatar-uploader .el-upload {
		width: 100%;
		height: 100%;
		border-radius: 59px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	}
	.U_form .el-form-item__label{
		padding-left: 24px;
		color: #999;
	}
	.U_form .onlyInput .el-input {
	    width: 588px;
	    margin-right: 15px;
	}
	.upBtn_box .el-form-item__content{
		margin-left: 0 !important;
		text-align: center;
	}
	.changeDialog .el-dialog__header{
		background-color: #4D6EF2;
		padding: 0;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}
	.changeDialog .el-dialog__title{
		color: #fff;
		font-size: 16px;
	}
	.changeDialog .el-dialog__headerbtn .el-dialog__close{
		color: #FFF;
	}
	.changeDialog .el-dialog__headerbtn{
		top: 14px;
		font-size: 20px;
	}
	.changeDialog .el-dialog__body{
		padding-bottom: 0;
	}
</style>
