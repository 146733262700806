<template>
	<div class="cipher">
		<el-form :model="pwdsetForm" ref="pwdsetForm" label-width="136px" class="pwdsetForm">
			<el-form-item label="旧密码" class="is-required onlyInput" :class="{'is-error':pwdsetRules.old_password}">
				<el-input v-model="pwdsetForm.old_password" minlength="6" maxlength="18" show-password @blur="blurPassword(1)" @input="Password(1)"></el-input>
				<div class="errorText" v-if="pwdsetRules.old_password"><i class="el-icon-warning"></i>{{old_password}}</div>
			</el-form-item>
			<el-form-item label="新密码" class="is-required onlyInput" :class="{'is-error':pwdsetRules.stu_password}">
				<el-input v-model="pwdsetForm.stu_password" minlength="6" maxlength="18" show-password @blur="blurPassword(2)" @input="Password(2)"></el-input>
				<div class="errorText" v-if="pwdsetRules.stu_password"><i class="el-icon-warning"></i>{{stu_password}}</div>
			</el-form-item>
			<el-form-item label="确认密码" class="is-required onlyInput" :class="{'is-error':pwdsetRules.Con_password}">
				<el-input v-model="pwdsetForm.Con_password" minlength="6" maxlength="18" show-password @blur="blurConpassword" @input="Password(3)"></el-input>
				<div class="errorText" v-if="pwdsetRules.Con_password"><i class="el-icon-warning"></i>{{Con_password}}</div>
			</el-form-item>
			<el-form-item class="pwdsetBtn_box">
				<el-button class="pwdsetBtn" type="primary" @click="submitForm()">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pwdsetForm: {
					old_password:"",//旧密码
					stu_password:"",//新密码
					Con_password:"",//确认密码
				},
				pwdsetRules:{//验证
					old_password:false,//旧密码
					stu_password:false,//新密码
					Con_password:false,//确认密码
				},
				old_password:"",//旧密码提示
				stu_password:"",//新密码提示
				Con_password:"",//确认密码提示
			}
		},
		methods: {
			//密码只能输入数字字母
			Password(type){
				if(type == 1){
					this.pwdsetForm.old_password = this.pwdsetForm.old_password.replace(/[^\da-zA-Z]/g,"");
				}else if(type == 2){
					this.pwdsetForm.stu_password = this.pwdsetForm.stu_password.replace(/[^\da-zA-Z]/g,"");
				}else if(type == 3){
					this.pwdsetForm.Con_password = this.pwdsetForm.Con_password.replace(/[^\da-zA-Z]/g,"");
				}
			},
			//密码验证
			blurPassword(type){
				if(type == 1){
					if(this.pwdsetForm.old_password){
						var pLength = this.pwdsetForm.old_password.length;
						if(pLength < 6 || pLength > 18){
							this.old_password = "请输入长度为8-16位的密码"
							this.pwdsetRules.old_password = true;
						}else{
							this.old_password = ""
							this.pwdsetRules.old_password = false;
						}
					}else{
						this.old_password = "请输入密码"
						this.pwdsetRules.old_password = true;
					}
				}else if(type == 2){
					if(this.pwdsetForm.stu_password){
						var pLength = this.pwdsetForm.stu_password.length;
						if(pLength < 6 || pLength > 18){
							this.stu_password = "请输入长度为8-16位的密码"
							this.pwdsetRules.stu_password = true;
						}else{
							this.stu_password = ""
							this.pwdsetRules.stu_password = false;
						}
					}else{
						this.stu_password = "请输入密码"
						this.pwdsetRules.stu_password = true;
					}
				}
			},
			//确认密码验证
			blurConpassword(){
				if(this.pwdsetForm.Con_password){
					if(this.pwdsetForm.stu_password){
						if(this.pwdsetForm.stu_password == this.pwdsetForm.Con_password){
							this.stu_password = ""
							this.pwdsetRules.Con_password = false;
						}else{
							this.Con_password = "两次密码输入不一致"
							this.pwdsetRules.Con_password = true;
						}
					}else{
						//密码验证
						this.blurPassword();
					}
				}else{
					this.Con_password = "请输入确认密码"
					this.pwdsetRules.Con_password = true;
				}
			},
			//确认提交
			submitForm(){
				//旧密码验证
				this.blurPassword(1)
				//新密码验证
				this.blurPassword(2)
				//确认密码验证
				this.blurConpassword();
				if(!this.pwdsetRules.old_password && !this.pwdsetRules.stu_password && !this.pwdsetRules.Con_password){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Info/changepassword.html", {
						password:this.pwdsetForm.stu_password,
						oldpassword:this.pwdsetForm.old_password,
						token:window.sessionStorage.getItem('token')
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.$message({
								message: response.data.msg,
								type: 'success',
								showClose: true
							});
							this.$cookies.remove("account");
							this.$cookies.remove("password");
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							this.$router.push({
								path: "/"
							});
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.onlyInput .el-input{
		width: 300px;
		margin-right: 15px;
	}
	.pwdsetBtn{
		width: 90px;
		height: 36px;
		background: #4d6ef2;
		border: 1px solid #4d6ef2;
		border-radius: 4px;
		text-align: center;
		line-height: 36px;
		padding: 0;
	}
	.errorText{
		display: inline-block;
		color: #f56c6c;
		font-size: 14px;
		line-height: 40px;
	}
	.errorText i{
		margin-right: 5px;
	}
</style>
